<template>
  <div class="content-wrapper platform-goods-image">
    <!-- Main content -->
    <section class="content">
      <breadcrumb></breadcrumb>
      <div>
        <a-row>
          <a-col :span="5">
            <SelectShop :code.sync="shopCode" @selected="selectedShop" />
          </a-col>
          <a-col :span="5" style="margin-left: 15px">
            <a-input
              class="keyword"
              v-model="nameOrSellerSku"
              :allowClear="true"
              :placeholder="$t('请输入商品名称或者商家编码')"
            ></a-input>
          </a-col>
          <a-col :span="5" style="margin-left: 15px">
            <a-button type="primary" @click="getTableData">
              <i class="icon iconfont icon-search" style="margin-right: 5px"></i>
              {{ $t('查询') }}
            </a-button>
            <a-button @click="reset">
              <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
              {{ $t(`重置`) }}
            </a-button>
          </a-col>
        </a-row>

        <!-- <a-button type="primary"> {{ $t("查询") }}</a-button> -->
      </div>
      <div class="header-content">
        <div class="tab-content">
          <a-tabs default-active-key="1" @change="changeTabs">
            <a-tab-pane :key="1">
              <template slot="tab">
                <span>{{ $t('全部') }}</span>
                <span v-if="totalCount && !imageMiss">（{{ totalCount }}）</span>
              </template>
            </a-tab-pane>
            <a-tab-pane :key="2">
              <template slot="tab">
                <span>{{ $t('图片缺失') }}</span>
                <span v-if="totalCount && imageMiss">（{{ totalCount }}）</span>
              </template>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div class="date-content">
          <span>{{ $t('大促时间') }}：</span>
          <DateRangePicker
            :needhms="true"
            :singleDatePicker="true"
            :startTime="activityDate"
            @get_date="setActiveDate"
          />
        </div>
      </div>
      <div class="table-content">
        <div class="table-body">
          <a-table
            rowKey="skuId"
            :columns="tableColumns"
            :data-source="tableData"
            :pagination="false"
            size="small"
            bordered
            :scroll="{ y: 550 }"
          >
            <template slot="images" slot-scope="text, scope">
              <div class="image-list" v-for="(item, key) in scope.skuImageMapping" :key="key">
                <div
                  class="flex"
                  v-if="
                    (scope.inActivity && (key === 'CBMO' || key === 'DDay')) || key === 'Teasing'
                  "
                >
                  <span class="type">{{ key }}</span>
                  <a-upload
                    :action="requestUrl"
                    :headers="{ 'Authorization': token }"
                    :multiple="true"
                    list-type="picture-card"
                    :file-list="item"
                    :data="{ shopCode, platform }"
                    :remove="handleRemove"
                    @preview="handlePreview"
                    @change="handleChange(scope, key, $event)"
                  >
                    <div v-if="item.length < 8">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">Upload</div>
                    </div>
                  </a-upload>
                </div>
              </div>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </template>
            <template slot="action" slot-scope="text, scope">
              <div class="action">
                <div class="text-info" v-if="scope.inActivity" @click="leaveD(scope)">
                  {{ $t('退出大促') }}
                </div>
                <div class="text-info" v-else @click="joinD(scope)">
                  {{ $t('参加大促') }}
                </div>
              </div>
            </template>
          </a-table>
        </div>
        <div class="table-footer">
          <Pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="totalCount"
            @paginate="paginate"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import http from '@service/axios'
import api from '@service/api'
import { Modal } from 'ant-design-vue'
import getBase64 from '@common/getBase64'
import SelectShop from '@component/selectShop'
import Pagination from '@component/pagination'
import DateRangePicker from '@component/dateRangePicker'
import { getCookie } from '@/common/cookie'
export default {
  name: 'platformGoodsImage',
  activated() {
    if (this.$route.query.id) {
      this.nameOrSellerSku = this.$route.query.id
      this.getTableData()
    }
    this.queryActiveDate()
  },
  components: { Pagination, DateRangePicker, SelectShop },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      imageMiss: false,
      tableColumns: [
        {
          title: this.$t('店铺'),
          dataIndex: 'shopName',
          key: 'shopName',
          width: 110,
          align: 'center',
        },
        {
          title: this.$t('商品'),
          dataIndex: 'name',
          key: 'name',
          width: 200,
          align: 'center',
        },
        {
          title: this.$t('商家编码'),
          dataIndex: 'sellerSku',
          key: 'sellerSku',
          width: 200,
          align: 'center',
        },
        {
          title: this.$t('图片'),
          dataIndex: 'images',
          key: 'images',
          align: 'center',
          scopedSlots: { customRender: 'images' },
        },
        {
          title: this.$t('操作'),
          key: 'action',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      tableData: [],
      shopCode: '',
      platform: '',
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      changeType: '',
      nameOrSellerSku: '',
      activityDate: null,
    }
  },
  computed: {
    requestUrl() {
      let cpid = this.$store.getters.currentShop?.partyId
      return api.uploadLocal + '?cpid=' + cpid
    },
    token() {
      return getCookie('d_session_id')
    },
  },
  mounted() {
    setTimeout(() => {
      this.getTableData()
    }, 100)
  },
  methods: {
    reset() {
      this.nameOrSellerSku = ''
      this.getTableData()
    },
    changeTabs(value) {
      this.imageMiss = value === 2
      this.totalCount = ''
      this.$nextTick(() => {
        this.getTableData()
      })
    },
    handleRemove() {
      return new Promise((resolve) => {
        this.changeType = 'remove'
        Modal.confirm({
          title: 'Confirm',
          content: this.$t('确认删除'),
          okText: this.$t('确认'),
          cancelText: this.$t('取消'),
          onOk() {
            resolve()
          },
        })
      })
    },
    getTableData() {
      http({
        instance: this,
        type: 'post',
        url: api.queryLazadaGoodsImage,
        hasLoading: true,
        data: {
          shopCode: this.shopCode,
          platform: this.platform,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          imageMiss: this.imageMiss,
          nameOrSellerSku: this.nameOrSellerSku,
        },
        success: (res) => {
          let list = [...res.result.lazadaImageList]
          list.forEach((item) => {
            let arr_mapping = {},
              map = new Map()
            ;[...item.urlList].forEach((item, index) => {
              let type = item.type
              let obj = {
                uid: '-' + (index + 1),
                name: `image${index}.png`,
                status: 'done',
                url: item.url,
              }
              if (map.has(type)) {
                let elem = map.get(type)
                elem.push(obj)
                map.set(type, elem)
              } else {
                let elem = [obj]
                map.set(type, elem)
              }
            })
            map.forEach((value, key) => {
              arr_mapping[key] = [...value]
            })
            if (item.inActivity) {
              if (!arr_mapping['CBMO']) {
                this.$set(arr_mapping, 'CBMO', [])
              }
              if (!arr_mapping['DDay']) {
                this.$set(arr_mapping, 'DDay', [])
              }
            }
            this.$set(item, 'skuImageMapping', arr_mapping)
          })
          this.tableData = list
          this.totalCount = res.result.total
        },
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange(scope, key, { file, fileList }) {
      scope.skuImageMapping[key] = fileList
      if (this.changeType === 'remove') {
        this.setLazadaGoodsImages(scope.skuId, scope.skuImageMapping[key], key)
        this.changeType = ''
      } else {
        this.$nextTick(() => {
          if (file.response) {
            let url = file.response.result.url
            scope.skuImageMapping[key][fileList.length - 1].url = url
            this.setLazadaGoodsImages(scope.skuId, scope.skuImageMapping[key], key)
          }
        })
      }
    },
    setLazadaGoodsImages(skuId, fileList, type) {
      let urlList = fileList.map((item) => item.url)
      http({
        instance: this,
        type: 'post',
        url: api.setLazadaGoodsImages,
        hasLoading: true,
        data: {
          skuId,
          type,
          urlList,
        },
        success: () => {
          this.$success(this.$t('操作成功'))
        },
      })
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.getTableData()
    },
    joinD(scope) {
      http({
        instance: this,
        type: 'post',
        url: api.inActivity,
        hasLoading: true,
        data: {
          skuId: scope.skuId,
        },
        success: () => {
          scope.inActivity = true
          if (!scope.skuImageMapping['CBMO']) {
            this.$set(scope.skuImageMapping, 'CBMO', [])
          }
          if (!scope.skuImageMapping['DDay']) {
            this.$set(scope.skuImageMapping, 'DDay', [])
          }
        },
      })
    },
    leaveD(scope) {
      http({
        instance: this,
        type: 'post',
        url: api.outActivity,
        hasLoading: true,
        data: {
          skuId: scope.skuId,
        },
        success: () => {
          scope.inActivity = false
          // let arr = JSON.parse(
          //   JSON.stringify(scope.skuImageMapping["Teasing"])
          // );
          // let skuImageMapping = {
          //   Teasing: arr,
          // };
          // this.$set(scope, "skuImageMapping", skuImageMapping);
        },
      })
    },
    queryActiveDate() {
      http({
        instance: this,
        type: 'post',
        url: api.queryActiveDate,
        data: {},
        success: (res) => {
          this.activityDate = res.result.activityDate
        },
      })
    },
    setActiveDate(date) {
      http({
        instance: this,
        type: 'post',
        url: api.setActiveDate,
        data: {
          activityDate: date,
        },
      })
    },
    selectedShop(data) {
      this.shopCode = data.code
      this.platform = data.platform
    },
  },
}
</script>

<style lang="scss" scoped>
.header-content {
  display: flex;
  .tab-content {
    flex: 1;
  }
  .date-content {
    display: flex;
    align-items: center;
  }
}
.form-content {
  display: flex;
  justify-content: flex-start;
  .keyword {
    width: 300px;
    margin-right: 10px;
  }
  .title {
    flex: 1;
  }
}
.image {
  width: 100%;
}
.image-list {
  align-items: center;
  .type {
    font-weight: bold;
    margin-right: 10px;
    width: 55px;
  }
}
.text-info {
  cursor: pointer;
  white-space: nowrap;
}
.avatar-uploader > .ant-upload {
  width: 100px;
  height: 100px;
}
.ant-upload-select-picture-card i {
  font-size: 22px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
// ::v-deep .ant-tabs-tab {
//   // color: violet;
//   width: 200px !important;
//   // display: flex;
//   // justify-content: space-around;
// }
::v-deep .ant-tabs-bar {
  border: none;
}
</style>

<style lang="scss" scopd>
.platform-goods-image {
  .ant-upload-list-picture-card-container,
  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload.ant-upload-select-picture-card {
    width: 80px;
    height: 80px;
  }
}
</style>
